.navbar {
  position: sticky;
  top: 0;
  z-index: 600;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  border-bottom: 1px solid #ccc;
  box-shadow: 0px 5px 7px -7px rgba(0, 0, 0, 0.75);
}

.navbar__logo:hover {
  cursor: pointer;
}

.navbar__middle {
  display: none;
}

.navbar__item {
  padding: 15px;
  text-align: center;
  color: #aaa;
}

.navbar__item:hover {
  background-color: #eee;
  border-radius: 15px;
  cursor: pointer;
  color: #338e4e;
  transform: translateY(-3px);
  box-shadow: -2px 5px 5px -6px rgba(0, 0, 0, 0.75);
}

.navbar__active > div {
  color: #338e4e;
  transform: translateY(3px) !important;
  box-shadow: none !important;
  background-color: #eee;
  border-radius: 15px;
}

.navbar__item > .MuiSvgIcon-root :nth-child() /*Glitch?*/ {
  color: #aaa;
}

.navbar__logo > img {
  height: 100px;
  width: auto;
  object-fit: contain;
}

.navbar__end {
  display: none;
}

.navbar__links {
  position: relative;
  top: 20px;
}

.navbar__links p {
  padding: 5px;
}

.navbar__links > p:hover {
  color: #338e4e;
  cursor: pointer;
  background-color: #eee;
  border-radius: 8px;
}

.navbar__links > a > p:hover {
  color: #338e4e;
  cursor: pointer;
  background-color: #eee;
  border-radius: 8px;
}

.navbar__endactive {
  color: #338e4e;
}

.navbar__burger {
  margin-right: 0;
}

@media (min-width: 870px) {
  .navbar__middle {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: space-evenly;
  }

  .navbar__end {
    display: flex;
    flex-direction: column;
    flex: 0.2;
    padding-right: 10px;
    align-items: space-between;
    justify-content: center;
    color: #aaa;
  }

  .navbar__links {
    display: flex;
    justify-content: space-between;
  }

  .navbar__languages {
    position: relative;
    top: 30px;
    display: flex;
  }

  .navbar__languages p {
    margin-left: 10px;
    font-size: 18px;
  }

  .navbar__burger {
    display: none;
  }
}
