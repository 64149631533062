.slideshow__slide1 {
  height: 75vh;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../assets//SlideshowImages/img1.png');
}

.slideshow__slide2 {
  height: 75vh;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../assets//SlideshowImages/img2.jpg');
}

.slideshow__slide3 {
  height: 75vh;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../assets//SlideshowImages/img3.jpg');
}

.slideshow__slide4 {
  height: 75vh;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../assets//SlideshowImages/img4.jpg');
}

.slideshow__slide5 {
  height: 75vh;
  width: 100%;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url('../assets//SlideshowImages/img5.jpg');
}