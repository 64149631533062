.sidedrawer {
  position: fixed;
  z-index: 500;
  left: 0;
  color: #4c4c4c;
  width: 100vw;
  border: 1px solid #aaa;
  background-color: rgba(255, 255, 255, 0.8);
  overflow: hidden;
}

.sidedrawer__list {
  list-style-type: none;
  padding: 0;
}

.sidedrawer__item {
  display: flex;
  width: 100%;
  align-items: center;
  padding: 5px 20px;
  margin: 5px 0;
}

.sidedrawer__item:hover {
  color: #338e4e;
  cursor: pointer;
  background-color: rgba(199, 199, 199, 0.5);
}

.sidedrawer__item > p {
  margin-left: 10px;
}

.sidedrawer__active > li {
  color: #338e4e;
  background-color: rgba(199, 199, 199, 0.5);
}

.sidedrawer__languages {
  border-top: 1px solid #aaa;
}

.sidedrawer__languages p {
  margin-left: 10px;
  font-size: 20px;
}

@media (min-width: 500px) {
  .sidedrawer {
    max-width: 50vw;
    left: 50%;
  }
}

@media (min-width: 870px) {
  .sidedrawer {
    display: none;
  }
}
