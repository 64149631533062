.app {
  width: 100%;
}

.app__imgslider {
  height: 450px;
  width: 100%;
  background-color: #eee;
}

.app__languages {
  position: absolute;
  right: 0;
  top: -10px;
  z-index: 700;
}

.app__languages {
  background-color: rgba(255, 255, 255, 0.7);
}

.app__languages h4 {
  margin-left: 10px;
}
