* {
  box-sizing: border-box;
  text-align: center;
}

* h3 {
  margin-bottom: 20px;
  color: #338e4e;
}

.sections {
  font-size: 24px;
  color: #4c4c4c;
  width: 100%;
  line-height: 1.25;
}

.sections__about {
  display: flex;
  justify-content: center;
  background-image: url("../assets/SPS_LOGO.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
}

.sections__aboutcontent {
  width: 100%;
  padding: 20px;
  margin: 20px 0;
  text-align: center;
  background-color: rgba(255, 255, 255, 0.7);
}

.sections__aboutcontent > p {
  width: 100%;
  padding: 0 40px;
}

/* Carpet Section Styling */

.sections__carpet {
  display: flex;
  width: 100%;
  background-image: url("../assets/SectionsImages/carpet.jpg");
  background-position: 0 75%;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: right;
}

.sections__carpetcontent {
  text-align: center;
  width: 100%;
  padding: 20px;
  margin: 20px;
  margin-bottom: 50%;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 15px;
}

/* Stain Removal Section Styling */

.sections__stains {
  display: flex;
  width: 100%;
  background-image: url("../assets/SectionsImages/stain1.jpg");
  background-position: 0 55%;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: right;
}

.sections__stainscontent {
  margin: 20px;
  width: 100%;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 15px;
}

/* Carpet Cleaning Process Section Styling */

.sections__softprocess {
  display: flex;
  flex-direction: column-reverse;
  background-color: #ddd;
  border-bottom: 1px solid #ddd;
}

.sections__softleft {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 20px;
}

.sections__softimage {
  display: flex;
  justify-content: center;
}

.sections__softimage > img {
  object-fit: cover;
  height: 180px;
  max-width: 80%;
}

.sections__softdisclaimer {
  margin-top: 20px;
  color: #338e4e;
}

.sections__softlist {
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 20px;
  border-radius: 15px;
}

.sections__softlist > ol {
  padding: 0;
}

.sections__softlist > ol > li {
  margin-top: 20px;
  list-style: none;
}

.sections__softlist > ol > li:hover {
  color: #338e4e;
  cursor: pointer;
}

/* Wood Section Styling */

.sections__wood {
  border-top: 1px solid #ddd;
  display: flex;
  width: 100%;
  background-image: url("../assets/SectionsImages/woodfloor2.jpg");
  background-position: 0 75%;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: right;
}

.sections__woodcontent {
  margin: 20px;
  text-align: left;
  width: 100%;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 15px;
}

/* Stone Section Styling */

.sections__stone {
  display: flex;
  width: 100%;
  background-image: url("../assets/SectionsImages/img2-stone.jpg");
  background-position: 0 55%;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: right;
}

.sections__stonecontent {
  margin: 20px;
  width: 100%;
  padding: 20px 60px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 15px;
}

/* Tile Section Styling */

.sections__tiles {
  border-top: 1px solid #ddd;
  display: flex;
  width: 100%;
  background-image: url("../assets/SectionsImages/tiles1.png");
  background-position: 0 75%;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: right;
}

.sections__tilescontent {
  margin: 20px;
  text-align: left;
  width: 100%;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 15px;
}

/* Safety Section Styling */

.sections__safety {
  display: flex;
  width: 100%;
  background-image: url("../assets/SectionsImages/safety1.jpg");
  background-position: 0 55%;
  background-repeat: no-repeat;
  background-size: cover;
  justify-content: right;
}

.sections__safetycontent {
  margin: 20px;
  width: 100%;
  padding: 20px 60px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 15px;
}

/* Hard Floor Cleaning Process Section Styling */

.sections__hardprocess {
  display: flex;
  flex-direction: column-reverse;
  background-color: #eee;
  border-bottom: 1px solid #ddd;
}

.sections__hardleft {
  width: 100%;
  padding: 20px;
}

.sections__hardimage {
  display: flex;
  justify-content: center;
  height: 180px;
}

.sections__hardimage > img {
  object-fit: contain;
}

.sections__harddisclaimer {
  margin-top: 20px;
  color: #338e4e;
}

.sections__hardlist {
  margin: 20px;
  display: flex;
  flex-direction: column;
  align-items: left;
  background-color: rgba(255, 255, 255, 0.7);
  padding: 20px;
  border-radius: 15px;
}

.sections__hardlist > ol {
  padding: 0;
  list-style: none;
}

.sections__hardlist > ol > li {
  margin-top: 20px;
}

.sections__hardlist > ol > li:hover {
  color: #338e4e;
  cursor: pointer;
}

/* Testimonials Styling */

.sections__testimonials {
  background-color: #eee;
  padding: 20px;
}

.sections__testimonials > h3 {
  text-align: center;
}
.sections__testimonial {
  background-color: #fff;
  border: 1px solid #ddd;
  border-radius: 15px;
  width: 98%;
  padding: 20px;
  margin: 20px auto;
  box-shadow: 0px 5px 7px -7px rgba(0, 0, 0, 0.75);
}

.sections__testimonial > img {
  max-height: 50px;
  object-fit: contain;
  margin-bottom: 10px;
}

.sections__testimonial > p:nth-of-type(2) {
  text-align: right;
  margin-top: 20px;
  font-size: 16px;
}

.sections__testimonial > p:nth-of-type(2) > .MuiSvgIcon-root {
  vertical-align: middle;
  color: #ccc;
}

@media (min-width: 870px) {
  * {
    text-align: left;
  }

  .sections__aboutcontent > p {
    width: 50%;
    padding-left: 40px;
  }

  .sections__carpetcontent {
    width: 70%;
    margin-left: 50%;
    margin-bottom: 20px;
  }

  .sections__tilescontent,
  .sections__woodcontent,
  .sections__tilescontent {
    width: 70%;
    margin-left: 50%;
  }

  .sections__stainscontent,
  .sections__softlist,
  .sections__stonecontent,
  .sections__safetycontent,
  .sections__hardleft,
  .sections__hardlist {
    width: 50%;
  }

  .sections__softprocess {
    flex-direction: row;
  }

  .sections__softleft {
    width: 50%;
    padding: 20px;
  }

  .sections__softdisclaimer {
    padding-left: 40px;
  }

  .sections__hardprocess {
    flex-direction: row;
  }

  .sections__harddisclaimer {
    padding-left: 40px;
  }

  .sections__testimonial > img {
    max-height: 250px;
  }
}
