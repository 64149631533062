.footer {
    position: relative;
    bottom: 0;
    padding: 10px;
    height: auto;
    width: 100%;
    background-color: #338e4e;
}

.footer__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer__top > form {
    color: #fff;
    display: flex;
    flex-direction: column;
    width: 100%;
}

.footer__top > form > input {
    margin-top: 20px;
    background-color: #338e4e;
    border: none;
    border-bottom: 1px solid #fff;
    color: #fff;
    outline: none;
    width: 100%;
}

.footer__top > form > textarea {
    margin-top: 20px;
    background-color: #338e4e;
    border: none;
    border-bottom: 1px solid #fff;
    color: #fff;
    outline: none;
    width: 100%;
}

.footer__top > form > input::placeholder {
    color: #ccc;
}

.footer__top > form > textarea::placeholder {
    color: #ccc;
}

.footer__bottom {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
}

.footer__start {
    color: #fff;
}

.footer__start > p {
    display: flex;
    margin-bottom: 10px;
    align-items: center;
}

.footer__start > p > .MuiSvgIcon-root {
    margin-right: 5px;
}

.footer__end {
    display: flex;
    flex-direction: column-reverse;
    color: #fff;
    align-items: center;
    justify-content: center;
}

.footer__end > a > .MuiSvgIcon-root{
    color: #fff;
}

.footer__end > a > .MuiSvgIcon-root:hover {
    color: #2e81f4;
    background-color: #fff;
    border-radius: 5px;
}

.footer__end > p {
    margin: 0;
    margin-top: 10px;
}

.footer__gwd {
    padding: 5px;
}

.footer__gwd:hover {
    cursor: pointer;
    color: #4ccc;
    background-color: #4c4c4c;
    border-radius: 5px;
}

@media (min-width: 400px) {
    .footer {
        padding: 40px;
    }

    .footer__end {
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (min-width: 870px) {
    .footer__top > form {
        width: 50%;
    }
}