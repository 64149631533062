.button__contact {
    margin: 40px auto;
    display: block;
    padding: 1rem 2rem;
    background-color: white;
    color: #338E4E;
    border: 1px solid #338E4E;
    border-radius: 0;
    justify-self: center;
    font-size: 24px;
    outline: none;
}
.button__contact:hover {
    background-color: #338E4E;
    color: white;
    cursor: pointer;
}

.button__contact:disabled {
    background-color: #ff4747;
    cursor: not-allowed;
    border: none;
    color: white;
    font-size: 24px;
    margin: 40px auto;
}

.button__footer {
    margin: 40px auto;
    display: block;
    padding: 8px 16px;
    background-color: #338E4E;
    color: #fff;
    border: 1px solid #fff;
    border-radius: 0;
    justify-self: center;
    font-size: 16px;
    outline: none;
}

.button__footer:hover {
    background-color: #fff;
    color: #338E4E;
    cursor: pointer;
}