.contactform {
    box-sizing: border-box;
    position: fixed;
    top: 40px;
    left: 10%;
    margin: auto;
    padding: 40px;
    padding-bottom: 0;
    z-index: 750;
    background-color: #fff;
}

.contactform__top {
    display: flex;
    justify-content: space-between;
}

.contactform__top > .MuiSvgIcon-root {
    color: #338e4e;
}

.contactform__top > .MuiSvgIcon-root:hover {
    color: rgb(228, 107, 107);
    cursor: pointer;
    background-color: #eee;
    border-radius: 6px;
}

.contactform > form {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin: auto;
}

.contactform > form > input {
    margin-bottom: 10px;
    border: none;
    border-bottom: 1px solid #ccc;
    outline: none;
}

.contactform > form > textarea {
    border: none;
    border-bottom: 1px solid #ccc;
    outline: none;
}

.contactform > form > p {
    margin-top: 10px;
    color: #4c4c4c;
}

@media (min-width: 500px) {
    .contactform {
        width: 80%;
    }
}